import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Toolbar,
  Paper,
  Typography,
  AppBar,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  theme,
  StyledSearch,
  StyledSearchIconWrapper,
  StyledInputBase,
  StyledButtonReload,
  StylePaperLoad,
} from "./Styled-Pagos";
import Cookies from "js-cookie";
import { Header, MenuLateral } from "../Header/Header";
import { addDetallePago, addPago } from "../Data/Pagos-Data";
import { UpdateAccessToken } from "../Router/Cookies";
import PagosTabla from "./PagosTabla";
import { FormatoMoneda } from "../Data/DatosSAT";
import CircularProgress from "@mui/material/CircularProgress";

function Pagos(props) {
  const [filtro, setFiltro] = React.useState("");
  const { pagos, setPagos } = props;
  const [cargar, setCargar] = React.useState(true);

  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const access = Cookies.get("access");
  const id = Cookies.get("id");
  let IsSession = !!Cookies.get("access");

  React.useEffect(() => {
    fetchData(setPagos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (setPagos) => {
    setPagos([]);
    setCargar(true);
    const Values = {
      usuario: id,
      operador: operador,
    };
    const Peticion = new Request("api/pagos/getPagos", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });

    let DatosArray = [];
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      const Datos = data.data;

      Datos.map((item) =>
        DatosArray.push(
          addPago(
            item.Fila,
            item.IdPago,
            item.NomSociedad,
            item.CardName,
            item.U_SYS_PMUUID,
            item.DocEntry,
            item.DocNum,
            item.U_SYS_PMFECC,
            item.DocDate,
            item.PymntGroup,
            FormatoMoneda(item.DocTotal),
            item.U_SYS_PMFECA,
            item.CANCELED === "Y"
              ? "Cancelado"
              : item.U_SYS_PMESPA === "A"
              ? "Aceptada"
              : item.U_SYS_PMESPA === "R"
              ? "Rechazada"
              : item.U_SYS_PMESPA === "V"
              ? "Validación"
              : "Pendiente",

            item.U_SYS_PMCOMC ? item.U_SYS_PMCOMC : "",
            item.U_SYS_PMPDFC ? item.U_SYS_PMPDFC : "",
            item.U_SYS_PMXMLC ? item.U_SYS_PMXMLC : "",
            item.U_SYS_PMSNBD,
            item.DocCur ? item.DocCur : "",
            item.U_SYS_PMPARE,

            item.RFCEmisor,
            item.RFCReceptor,
            item.CPEmisor,
            item.CPReceptor,
            item.Uso,
            item.Regimen,

            item.Detalle.map((itemDetalle) =>
              addDetallePago(
                itemDetalle.FolioSAP,
                itemDetalle.UUID,
                itemDetalle.FechaFactura,
                itemDetalle.Total !== "" && itemDetalle.Total !== null
                  ? itemDetalle.Total
                  : 0.0,
                item.DocCur ? item.DocCur : ""
              )
            )
          )
        )
      );

      setPagos(DatosArray);
    } catch (error) {
      console.error(error);
    }
    setCargar(false);
  };

  if (!UpdateAccessToken()) {
    return <Navigate to="/" />;
  }

  const handleForma = (event) => {
    event.preventDefault();
    fetchData(setPagos);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Header
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
          operador={operador}
          usuario={usuario}
        />
        <MenuLateral
          IsSession={IsSession}
          open={props.open}
          setOpen={props.setOpen}
        />
        <Box
          sx={{
            flexGrow: 10,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <Typography
            color="text.secondary"
            align="center"
            variant="h4"
            component="h4"
            margin={2}
          >
            Complemento de pagos
          </Typography>
          <Paper sx={{ maxWidth: "90%", margin: "auto", overflow: "hidden" }}>
            <AppBar
              position="static"
              sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
            >
              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs>
                    <StyledSearch>
                      <StyledSearchIconWrapper>
                        <SearchIcon />
                      </StyledSearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ "aria-label": "search" }}
                        value={filtro}
                        onChange={(e) => setFiltro(e.target.value)}
                      />
                    </StyledSearch>
                  </Grid>
                  <Grid item xs align="right">
                    <Tooltip title="Recargar">
                      <StyledButtonReload
                        color="inherit"
                        onClick={handleForma}
                        size="medium"
                      >
                        <RefreshIcon
                          id="btnRefreshEmpleado"
                          color="inherit"
                          sx={{ display: "block" }}
                        />
                      </StyledButtonReload>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
              {<PagosTabla filtro={filtro} rows={pagos} />}
              <StylePaperLoad>
                {cargar === true && <CircularProgress />}
              </StylePaperLoad>
            </AppBar>
          </Paper>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

Pagos.propTypes = {
  pagos: PropTypes.array,
};

export default Pagos;
