import React from "react";
import PropTypes from "prop-types";
import { useNavigate, Navigate } from "react-router-dom";
import { Header, MenuLateral } from "../Header/Header";
import {
  Box,
  Toolbar,
  Typography,
  Select,
  Grid,
  Snackbar,
  MenuItem,
  TextField,
  Alert,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  theme,
  StyledButton,
  StylePaperPrincipal,
  StyledDiv,
  StyledTheme,
  StylePaperButton,
  StyledInputFile,
  StyledButtonEditar,
} from "./Styled-MisFacturas";
import Cookies from "js-cookie";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { convertToBase64 } from "../Data/ArchivoBase64";
import { UpdateAccessToken } from "../Router/Cookies";
import {
  obtenerUsoSAT,
  obtenerMetodPagoSAT,
  FormatoMoneda,
} from "../Data/DatosSAT";
import { parseString } from "xml2js";

function Factura(props) {
  const navigate = useNavigate();
  const [Generacion, setGeneracion] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [habilarBoton, setHabilarBoton] = React.useState(false);
  const [datosProveedor, setDatosProveedor] = React.useState(false);
  const [sociedades, setSociedades] = React.useState([]);
  const [entradas, setEntradas] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [errorMensaje, setErrorMensaje] = React.useState([]);
  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const access = Cookies.get("access");
  const id = Cookies.get("id");
  let IsSession = !!Cookies.get("access");
  let habilitar = true;
  const [idEntrada, setIdEntrada] = React.useState(-1);

  const [values, setValues] = React.useState({
    id: id,
    usuario: usuario,
    operador: operador,
    RazonSocial: "",
    EntradaMercancia: "",
    PDF: "",
    PDF64: "",
    XML: "",
    XML64: "",
    Recepcion: "",
    Recepcion64: "",
    FolioFactura: "",
    RFCEmisor: "",
    RFCReceptor: "",
    NombreEmisor: "",
    NombreReceptor: "",
    UUID: "",
    FechaFactura: "",
    Total: 0.0,
    FormaPago: "",
    UsoPrincipal: "",
    MetodoPago: "",
    CPReceptor: "",
    CPEmisor: "",
    Regimen: "",
    Moneda: "",
  });

  const getSociedades = async (setSociedades) => {
    const Values = {
      usuario: id,
      operador: operador,
    };
    const Peticion = new Request("/api/facturas/getSociedades", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      setSociedades(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getDatosProveedor = async (setDatosProveedor) => {
    const Values = {
      usuario: id,
      operador: operador,
    };
    const Peticion = new Request("/api/facturas/getProveedor", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      console.log(data.data.length);
      if (data.data.length > 0) {
        setDatosProveedor(false);
      } else {
        setDatosProveedor(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getEntradas = async (setEntradas, BD) => {
    const Values = {
      usuario: id,
      operador: operador,
      BD: BD,
      tipo: "Nuevo",
    };
    const Peticion = new Request("/api/facturas/getEntradas", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      setEntradas(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const addAlert = async (message, severity) => {
    setErrorMensaje((prevAlerts) => [...prevAlerts, { message, severity }]);
    setOpenMessage(true);
  };

  React.useEffect(() => {
    getSociedades(setSociedades);
    getDatosProveedor(setDatosProveedor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Validaciones = async () => {
    let err = "";
    let Valerr = false;
    //Razon social

    console.log(values.usuario);
    console.log(values.NombreEmisor);

    if (values.usuario !== values.NombreEmisor) {
      err = "El nombre de proveedor de la entrada no es el mismo del XML";
      Valerr = true;
      addAlert(err, "error");
      // return err;
    }
    sociedades.forEach((row) => {
      if (row.BDSAP === values.RazonSocial) {
        if (row.NomSociedad !== values.NombreReceptor) {
          err =
            "El nombre del receptor no es mismo de la sociedad seleccionada en el XML";
          Valerr = true;
          addAlert(err, "error");
          // return err;
        }

        if (row.Regimen !== values.Regimen) {
          err =
            "El régimen fiscal del receptor no es mismo de la sociedad seleccionada en el XML";
          Valerr = true;
          addAlert(err, "error");
          // return err;
        }

        if (row.CP !== values.CPReceptor) {
          err =
            "El código postal del receptor no es mismo de la sociedad seleccionada en el XML";
          Valerr = true;
          addAlert(err, "error");
          // return err;
        }
      }
    });

    entradas.forEach((row) => {
      if (row.DocNum === values.EntradaMercancia) {
        console.log(row.DocTotal);
        console.log(values.Total);
        if (
          FormatoMoneda(parseFloat(row.DocTotal)) !==
          FormatoMoneda(parseFloat(values.Total))
        ) {
          err = "El total de la entrada no es el mismo del XML";
          Valerr = true;
          addAlert(err, "error");
          // return err;
        }
        if (row.PeyMethod !== values.FormaPago) {
          err = "La forma de pago de la entrada no es el mismo del XML";
          Valerr = true;
          addAlert(err, "error");
          // return err;
        }
        if (row.U_B1SYS_MainUsage !== values.UsoPrincipal) {
          err =
            "El uso principal del emisor de la entrada no es el mismo del XML";
          Valerr = true;
          addAlert(err, "error");
          // return err;
        }
        if (row.CP !== values.CPEmisor) {
          err =
            "El código postal del emisor no es mismo de la sociedad seleccionada en el XML";
          Valerr = true;
          addAlert(err, "error");
          // return err;
        }
        values.Moneda = row.DocCur;
      }
    });

    return Valerr;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMensaje([]);
    setHabilarBoton(true);

    if (datosProveedor === false) {
      addAlert(
        "Revisar el estatus de su registro ya que debe de cargar o actualizar los archivos en tu perfil para poder cargar facturas",
        "error"
      );
      setError(true);
      setOpenMessage(true);
      setHabilarBoton(false);
      return;
    }

    if (values.PDF === "") {
      addAlert("Debe cargar el pdf", "error");
      setError(true);
      setOpenMessage(true);
      setHabilarBoton(false);
      return;
    }

    if (values.XML === "") {
      addAlert("Debe cargar el XML", "error");
      setError(true);
      setOpenMessage(true);
      setHabilarBoton(false);
      return;
    }

    if (values.Recepcion === "") {
      addAlert("Debe cargar el recepción", "error");
      setError(true);
      setOpenMessage(true);
      setHabilarBoton(false);
      return;
    }

    setError(false);
    setOpenMessage(false);

    const Validacion = await Validaciones();

    if (!Validacion) {
      const Peticion = new Request("/api/facturas/postFactura", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": access,
        },
        method: "POST",
        body: JSON.stringify(values),
      });

      try {
        const response = await fetch(Peticion);
        const data = await response.json();
        if (data.status === "success") {
          setGeneracion(true);
          setOpenMessage(true);
          setError(false);
        } else {
          setError(true);
          setOpenMessage(true);
          addAlert(data.message, "error");
        }
      } catch (error) {
        console.error("Failed to fetch from postFactura", error);
        setError(true);
        setOpenMessage(true);
        addAlert("Error al consumir el servicio postFactura", "error");
      }
    } else {
      setError(true);
      setOpenMessage(true);
      //setErrorMensaje(Validacion);
      setHabilarBoton(false);
    }
  }

  function handleChange(evt) {
    const { target } = evt;
    const { name, value } = target;
    let newValues;

    if (name === "RazonSocial") {
      getEntradas(setEntradas, value);
      newValues = {
        ...values,
        EntradaMercancia: "",
        PDF: "",
        PDF64: "",
        XML: "",
        XML64: "",
        Recepcion: "",
        Recepcion64: "",
        [name]: value,
      };
      setIdEntrada(-1);
    } else if (name === "EntradaMercancia") {
      entradas.forEach((row) => {
        if (row.DocNum === value) {
          setIdEntrada(row.Fila - 1);
        }
        newValues = {
          ...values,
          PDF: "",
          PDF64: "",
          XML: "",
          XML64: "",
          Recepcion: "",
          Recepcion64: "",
          [name]: value,
        };
      });
    } else {
      newValues = {
        ...values,
        [name]: value,
      };
    }

    console.log(newValues);
    setValues(newValues);
  }

  const Archivos = async (evt) => {
    const { target } = evt;
    const { name, files, id } = target;

    if (files[0] === undefined) {
      return;
    }

    var base64;
    try {
      const base64pdf = await convertToBase64(files[0]);
      var ArrayAuxiliar = [];
      ArrayAuxiliar = base64pdf.split(",");
      base64 = ArrayAuxiliar[1];
    } catch (error) {
      console.error("Error while converting to Base64:", error);
    }

    const newValues = {
      ...values,
      [name]: files[0].name,
      [id]: base64,
    };
    setValues(newValues);

    // Restablecer el input para permitir seleccionar el mismo archivo nuevamente
    evt.target.value = null;
  };

  const ArchivoXML = async (evt) => {
    const { target } = evt;
    const { name, files, id } = target;

    if (files[0] === undefined) {
      return;
    }

    var base64;
    try {
      const base64pdf = await convertToBase64(files[0]);
      var ArrayAuxiliar = [];
      ArrayAuxiliar = base64pdf.split(",");
      base64 = ArrayAuxiliar[1];
    } catch (error) {
      console.error("Error while converting to Base64:", error);
    }

    const file = evt.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const xmlContent = event.target.result;
        parseXML(xmlContent, name, file.name, base64, id);
      };
      reader.readAsText(file);
    }

    // Restablecer el input para permitir seleccionar el mismo archivo nuevamente
    evt.target.value = null;
  };

  const parseXML = (xmlContent, name, FileName, Base64, id) => {
    // Parse the XML string into a JavaScript object
    parseString(xmlContent, { explicitArray: false }, (err, result) => {
      if (err) {
        console.error("Error parsing XML:", err);
        return;
      }

      // El CFDI 4.0 tiene la estructura que puedes explorar
      // Accede a la ruta correcta para obtener el total (dependiendo de la estructura del XML)
      console.log(result["cfdi:Comprobante"].$);
      //["cfdi:Comprobante"]["cfdi:Emisor"].$.Nombre
      console.log(result);

      try {
        if (
          result["cfdi:Comprobante"].$ === undefined ||
          result["cfdi:Comprobante"].$.Fecha === undefined ||
          result["cfdi:Comprobante"].$.Folio === undefined ||
          result["cfdi:Comprobante"].$.LugarExpedicion === undefined ||
          result["cfdi:Comprobante"].$.Total === undefined ||
          result["cfdi:Comprobante"].$.FormaPago === undefined ||
          result["cfdi:Comprobante"].$.MetodoPago === undefined ||
          result["cfdi:Comprobante"]["cfdi:Emisor"].$.Rfc === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.Rfc === undefined ||
          result["cfdi:Comprobante"]["cfdi:Emisor"].$.Nombre === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.Nombre === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$
            .DomicilioFiscalReceptor === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.UsoCFDI === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$
            .RegimenFiscalReceptor === undefined ||
          result["cfdi:Comprobante"]["cfdi:Complemento"][
            "tfd:TimbreFiscalDigital"
          ].$.UUID === undefined
        ) {
          addAlert(
            "No cuenta con la estructura de un XML valida de un timbre del SAT",
            "error"
          );
          setError(true);
          setOpenMessage(true);
          return;
        }
      } catch (error) {
        addAlert(
          "No cuenta con la estructura de un XML valida de un timbre del SAT",
          "error"
        );
        setError(true);
        setOpenMessage(true);
        return;
      }

      //alert("Correcto");

      let Retenciones = 0;

      if (
        result["cfdi:Comprobante"]["cfdi:Impuestos"].$
          .TotalImpuestosRetenidos !== undefined
      ) {
        Retenciones =
          result["cfdi:Comprobante"]["cfdi:Impuestos"].$
            .TotalImpuestosRetenidos;
      }

      let Descuentos = 0;

      if (result["cfdi:Comprobante"].$.Descuento !== undefined) {
        Descuentos = result["cfdi:Comprobante"].$.Descuento;
      }

      const newValues = {
        ...values,
        [name]: FileName,
        [id]: Base64,
        FechaFactura: result["cfdi:Comprobante"].$.Fecha,
        FolioFactura: result["cfdi:Comprobante"].$.Folio,
        Total:
          parseFloat(result["cfdi:Comprobante"].$.Total) +
          parseFloat(Retenciones) +
          parseFloat(Descuentos),
        FormaPago: result["cfdi:Comprobante"].$.FormaPago,
        MetodoPago: result["cfdi:Comprobante"].$.MetodoPago,
        RFCEmisor: result["cfdi:Comprobante"]["cfdi:Emisor"].$.Rfc,
        RFCReceptor: result["cfdi:Comprobante"]["cfdi:Receptor"].$.Rfc,
        NombreEmisor: result["cfdi:Comprobante"]["cfdi:Emisor"].$.Nombre,
        NombreReceptor: result["cfdi:Comprobante"]["cfdi:Receptor"].$.Nombre,
        UsoPrincipal: result["cfdi:Comprobante"]["cfdi:Receptor"].$.UsoCFDI,
        UUID: result["cfdi:Comprobante"]["cfdi:Complemento"][
          "tfd:TimbreFiscalDigital"
        ].$.UUID,
        CPReceptor:
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.DomicilioFiscalReceptor,
        CPEmisor: result["cfdi:Comprobante"].$.LugarExpedicion,
        Regimen:
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.RegimenFiscalReceptor,
      };
      setValues(newValues);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenMessage(false);

    if (Generacion === true) {
      navigate("/MisFacturas");
    }
  };

  if (!UpdateAccessToken()) {
    return <Navigate to="/" />;
  }

  if (IsSession) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Header
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
            operador={Cookies.get("operador")}
            usuario={Cookies.get("usuario")}
          />
          <MenuLateral
            IsSession={IsSession}
            open={props.open}
            setOpen={props.setOpen}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 10,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Typography
              sx={{ my: 3, mx: 2 }}
              align="center"
              variant="h5"
              component="h5"
            >
              Registro de la facturación
            </Typography>

            <form onSubmit={handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperPrincipal variant="outlined">
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Razón social:
                    </Typography>
                    <Select
                      autoComplete="section-factura-Nueva-RazonSocial"
                      size="small"
                      fullWidth
                      align="left"
                      variant="outlined"
                      required
                      id="RazonSocial"
                      name="RazonSocial"
                      value={values.RazonSocial}
                      onChange={handleChange}
                    >
                      {sociedades !== null &&
                        sociedades.map((sociedad) => {
                          return (
                            <MenuItem
                              key={sociedad.IdSociedad}
                              id={sociedad.IdSociedad}
                              value={sociedad.BDSAP}
                            >
                              {sociedad.NomSociedad}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </StyledDiv>
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Entrada de mercancia:
                    </Typography>
                    <Select
                      autoComplete="section-factura-Nueva-EntradaMercancia"
                      size="small"
                      fullWidth
                      align="left"
                      variant="outlined"
                      required
                      id="EntradaMercancia"
                      name="EntradaMercancia"
                      value={values.EntradaMercancia}
                      onChange={handleChange}
                    >
                      {entradas !== null &&
                        entradas.map((entrada) => {
                          return (
                            <MenuItem
                              key={entrada.Fila}
                              id={entrada.Fila}
                              value={entrada.DocNum}
                            >
                              {entrada.DocNum}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </StyledDiv>
                  {/* PDF  */}
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *PDF:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-factura-Nueva-PDF"
                      size="small"
                      variant="outlined"
                      required
                      id="LabelPDF"
                      name="LabelPDF"
                      value={values.PDF}
                      disabled={true}
                    />
                    <StyledButtonEditar
                      size="medium"
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      endIcon={<CloudUploadIcon />}
                      id="btnPDF"
                      name="btnPDF"
                      onChange={Archivos}
                      multiple={false}
                    >
                      cargar
                      <StyledInputFile
                        id="PDF64"
                        name="PDF"
                        type="file"
                        accept="application/pdf"
                      />
                    </StyledButtonEditar>
                  </StyledDiv>

                  {/* XML  */}
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *XML:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-factura-Nueva-XML"
                      size="small"
                      variant="outlined"
                      required
                      id="LabelXML"
                      name="LabelXML"
                      value={values.XML}
                      disabled={true}
                    />
                    <StyledButtonEditar
                      size="medium"
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      endIcon={<CloudUploadIcon />}
                      id="BtnXML"
                      name="BtnXML"
                      onChange={ArchivoXML}
                      multiple={false}
                    >
                      cargar
                      <StyledInputFile
                        id="XML64"
                        name="XML"
                        type="file"
                        accept="text/xml"
                      />
                    </StyledButtonEditar>
                  </StyledDiv>
                  {/* Recepción  */}
                  <StyledDiv>
                    <Typography
                      align="left"
                      variant="subtitle2"
                      gutterBottom
                      sx={window.innerWidth > 500 && { width: 1 / 2 }}
                    >
                      *Recepción:
                    </Typography>
                    <TextField
                      fullWidth
                      align="left"
                      autoComplete="section-factura-Nueva-Recepcion"
                      size="small"
                      variant="outlined"
                      required
                      id="LabelRecepcion"
                      name="LabelRecepcion"
                      value={values.Recepcion}
                      disabled={true}
                    />
                    <StyledButtonEditar
                      size="medium"
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      endIcon={<CloudUploadIcon />}
                      id="BtnRecepcion"
                      name="BtnRecepcion"
                      onChange={Archivos}
                      multiple={false}
                    >
                      cargar
                      <StyledInputFile
                        id="Recepcion64"
                        name="Recepcion"
                        type="file"
                        accept="image/gif, image/jpeg, image/png"
                      />
                    </StyledButtonEditar>
                  </StyledDiv>
                </StylePaperPrincipal>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StylePaperButton variant="elevation" elevation={0}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={StyledTheme.submit}
                    disabled={habilarBoton}
                  >
                    Agregar
                  </StyledButton>
                </StylePaperButton>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {entradas !== undefined &&
                  entradas !== "" &&
                  entradas.length > 0 &&
                  idEntrada >= 0 && (
                    <StylePaperPrincipal variant="outlined">
                      <Typography
                        sx={{ my: 3, mx: 2 }}
                        align="center"
                        variant="h5"
                        component="h5"
                      >
                        Datos de la entrada
                      </Typography>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Pedido:
                        </Typography>
                        <TextField
                          fullWidth
                          align="left"
                          autoComplete="section-Entrada-Pedido"
                          size="small"
                          variant="outlined"
                          required
                          id="Pedido"
                          name="Pedido"
                          autoFocus
                          value={entradas[idEntrada].Pedido}
                          disabled={habilitar}
                          onChange={handleChange}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Entrada:
                        </Typography>
                        <TextField
                          fullWidth
                          align="left"
                          autoComplete="section-Entrada-Pedido"
                          size="small"
                          variant="outlined"
                          required
                          id="Pedido"
                          name="Pedido"
                          autoFocus
                          value={entradas[idEntrada].DocNum}
                          disabled={habilitar}
                          onChange={handleChange}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Sociedad:
                        </Typography>
                        <TextField
                          fullWidth
                          align="left"
                          autoComplete="section-Entrada-Sociedad"
                          size="small"
                          variant="outlined"
                          required
                          id="Sociedad"
                          name="Sociedad"
                          autoFocus
                          value={entradas[idEntrada].NomSociedad}
                          disabled={habilitar}
                          onChange={handleChange}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Proveedor:
                        </Typography>
                        <TextField
                          fullWidth
                          align="left"
                          autoComplete="section-Entrada-NomProveedor"
                          size="small"
                          variant="outlined"
                          required
                          id="NomProveedor"
                          name="NomProveedor"
                          autoFocus
                          value={entradas[idEntrada].CardName}
                          disabled={habilitar}
                          onChange={handleChange}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Fecha de la entrada:
                        </Typography>
                        <TextField
                          fullWidth
                          align="left"
                          autoComplete="section-Entrada-Fecha"
                          size="small"
                          variant="outlined"
                          required
                          id="Fecha"
                          name="Fecha"
                          value={entradas[idEntrada].DocDate}
                          disabled={habilitar}
                          onChange={handleChange}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Fecha de vencimiento:
                        </Typography>
                        <TextField
                          fullWidth
                          align="left"
                          autoComplete="section-Entrada-FechaVencimiento"
                          size="small"
                          variant="outlined"
                          required
                          id="FechaVencimiento"
                          name="FechaVencimiento"
                          value={entradas[idEntrada].DocDueDate}
                          disabled={habilitar}
                          onChange={handleChange}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Referencia:
                        </Typography>
                        <TextField
                          fullWidth
                          align="left"
                          autoComplete="section-Entrada-Referencia"
                          size="small"
                          variant="outlined"
                          required
                          id="Referencia"
                          name="Referencia"
                          value={entradas[idEntrada].NumAtCard}
                          disabled={habilitar}
                          onChange={handleChange}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Comentarios:
                        </Typography>
                        <TextField
                          multiline={true}
                          rows={3}
                          autoComplete="section-Entrada-Comentarios"
                          size="small"
                          fullWidth
                          variant="outlined"
                          required
                          id="Comentarios"
                          name="Comentarios"
                          value={entradas[idEntrada].Comments}
                          onChange={handleChange}
                          disabled={habilitar}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Estatus:
                        </Typography>
                        <TextField
                          autoComplete="section-Entrada-Estatus"
                          size="small"
                          fullWidth
                          variant="outlined"
                          required
                          id="Estatus"
                          name="Estatus"
                          value={
                            entradas[idEntrada].DocStatus === "O"
                              ? "Abierto"
                              : "Cerrado"
                          }
                          onChange={handleChange}
                          disabled={habilitar}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Uso principal:
                        </Typography>
                        <TextField
                          autoComplete="section-Entrada-UsoPrincipal"
                          size="small"
                          fullWidth
                          variant="outlined"
                          required
                          id="UsoPrincipal"
                          name="UsoPrincipal"
                          value={obtenerUsoSAT(
                            entradas[idEntrada].U_B1SYS_MainUsage
                          )}
                          onChange={handleChange}
                          disabled={habilitar}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Método de pago
                        </Typography>
                        <TextField
                          autoComplete="section-Entrada-MetodoPago"
                          size="small"
                          fullWidth
                          variant="outlined"
                          required
                          id="MetodoPago"
                          name="MetodoPago"
                          value={obtenerMetodPagoSAT(
                            entradas[idEntrada].GroupNum > 0 ? "PPD" : "PUE"
                          )}
                          onChange={handleChange}
                          disabled={habilitar}
                        />
                      </StyledDiv>
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          Total:
                        </Typography>
                        <TextField
                          autoComplete="section-Entrada-Estatus"
                          size="small"
                          fullWidth
                          variant="outlined"
                          required
                          id="Estatus"
                          name="Estatus"
                          value={
                            FormatoMoneda(entradas[idEntrada].DocTotal) +
                            " " +
                            entradas[idEntrada].DocCur
                          }
                          onChange={handleChange}
                          disabled={habilitar}
                        />
                      </StyledDiv>
                    </StylePaperPrincipal>
                  )}
              </Grid>
            </form>
          </Box>
        </Box>
        {Generacion === true && (
          <Snackbar
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            open={openMessage}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Se registro con exito la factura!
            </Alert>
          </Snackbar>
        )}
        {error === true && errorMensaje.length > 0 && (
          <Snackbar
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            autoHideDuration={30000}
            open={openMessage}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error" variant="filled">
              {errorMensaje.length > 0 &&
                errorMensaje.map((entrada) => {
                  return <div>{entrada.message}</div>;
                })}
              {/* {errorMensaje.length > 0 ? errorMensaje[0].message : ""} */}
            </Alert>
            {/* <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {errorMensaje}
            </Alert> */}
          </Snackbar>
        )}
      </ThemeProvider>
    );
  } else {
    return <Navigate to="/" />;
  }
}

Factura.propTypes = {
  MiFactura: PropTypes.array,
};

export default Factura;
