import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useNavigate, Navigate } from "react-router-dom";
import { Header, MenuLateral } from "../Header/Header";
import {
  Box,
  Toolbar,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Alert,
} from "@mui/material";
import { isAuthenticatedvalid } from "../Router/Cookies";
import { ThemeProvider } from "@mui/material/styles";
import {
  theme,
  StyledButton,
  StylePaperPrincipal,
  StyledDiv,
  StyledTheme,
  StylePaperButton,
  StyledInputFile,
  StyledButtonEditar,
} from "./Styled-Pagos";
import Cookies from "js-cookie";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { convertToBase64 } from "../Data/ArchivoBase64";
import createFile from "../Data/ArchivoBase64";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { FormatoMoneda } from "../Data/DatosSAT";
import { parseString } from "xml2js";

function Pago(props) {
  let params = useParams();

  const [values, setValues] = React.useState({
    id: "",
    PagoSAP: "",
    BD: "",
    PDF: "",
    PDF64: "",
    XML: "",
    XML64: "",
    Complemento: "",
    Complemento64: "",
    Folio: "",
  });

  const [Generacion, setGeneracion] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [habilarBoton, setHabilarBoton] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [datosProveedor, setDatosProveedor] = React.useState(false);
  const [errorMensaje, setErrorMensaje] = React.useState([]);
  const navigate = useNavigate();
  let IsSession = isAuthenticatedvalid("access");
  const usuario = Cookies.get("usuario");
  const operador = Cookies.get("operador");
  const access = Cookies.get("access");
  const id = Cookies.get("id");

  const getDatosProveedor = async (setDatosProveedor) => {
    const Values = {
      usuario: id,
      operador: operador,
    };
    const Peticion = new Request("/api/facturas/getProveedor", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": access,
      },
      method: "POST",
      body: JSON.stringify(Values),
    });
    try {
      const response = await fetch(Peticion);
      const data = await response.json();
      console.log(data.data.length);
      if (data.data.length > 0) {
        setDatosProveedor(false);
      } else {
        setDatosProveedor(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (props.pagos.length > 0) {
      setValues({
        usuario: usuario,
        operador: operador,
        id: props.pagos[params.id - 1].Numero,
        PagoSAP: props.pagos[params.id - 1].PagoSAP,
        BD: props.pagos[params.id - 1].BD,
        XML64: props.pagos[params.id - 1].XML,
        XML: props.pagos[params.id - 1].XML ? "Cargado" : "No cargado",
        PDF64: props.pagos[params.id - 1].PDF,
        PDF: props.pagos[params.id - 1].PDF ? "Cargado" : "No cargado",
        Complemento64: props.pagos[params.id - 1].Complemento,
        Complemento: props.pagos[params.id - 1].Complemento
          ? "Cargado"
          : "No cargado",
        FechaPago: props.pagos[params.id - 1].Fecha,
        Folio: props.pagos[params.id - 1].Folio,
        Total: parseFloat(
          props.pagos[params.id - 1].TotalPago.replace(/,/g, "")
        ),
        RFCEmisor: props.pagos[params.id - 1].RFCEmisor,
        RFCReceptor: props.pagos[params.id - 1].RFCReceptor,

        NombreEmisor: props.pagos[params.id - 1].NomProveedor,
        NombreReceptor: props.pagos[params.id - 1].Sociedad,

        // UsoPrincipal: props.pagos[params.id - 1].Uso,
        UUID: props.pagos[params.id - 1].UUID,
      });

      if (
        props.pagos[params.id - 1].EstatusComplemento === "Aceptada" ||
        props.pagos[params.id - 1].EstatusComplemento === "Cancelado"
      ) {
        setHabilarBoton(true);
      }

      getDatosProveedor(setDatosProveedor);
    } else {
      navigate("/Pagos");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addAlert = async (message, severity) => {
    setErrorMensaje((prevAlerts) => [...prevAlerts, { message, severity }]);
    setOpenMessage(true);
  };

  const Validaciones = async () => {
    let err = "";
    let Valerr = false;
    // //Emisor
    console.log(props.pagos[params.id - 1].NomProveedor);
    console.log(values.NombreEmisor);
    if (props.pagos[params.id - 1].NomProveedor !== values.NombreEmisor) {
      err = "El nombre de proveedor del pago no es el mismo del XML";
      Valerr = true;
      addAlert(err, "error");
    }

    //Receptor
    if (props.pagos[params.id - 1].Sociedad !== values.NombreReceptor) {
      err = "El nombre de la sociedad del pago no es el mismo del XML";
      Valerr = true;
      addAlert(err, "error");
    }
    if (
      FormatoMoneda(
        parseFloat(
          parseFloat(props.pagos[params.id - 1].TotalPago.replace(/,/g, ""))
        )
      ) != FormatoMoneda(parseFloat(values.Total))
    ) {
      err = "El total del pago no es el mismo del XML";
      Valerr = true;
      addAlert(err, "error");
    }

    return Valerr;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMensaje([]);
    let Validacion;

    if (operador === "Proveedor") {
      if (datosProveedor === false) {
        addAlert(
          "Revisar el estatus de su registro ya que debe de cargar o actualizar los archivos en tu perfil para poder cargar pagos",
          "error"
        );
        setError(true);
        setOpenMessage(true);
      }

      if (values.PDF64 === "") {
        addAlert("Debe cargar el pdf", "error");
        setError(true);
        setOpenMessage(true);
      }

      if (values.XML64 === "") {
        addAlert("Debe cargar el xml", "error");
        setError(true);
        setOpenMessage(true);
      }

      Validacion = await Validaciones();
    } else {
      if (values.Complemento64 === "") {
        addAlert("Debe cargar el complemento", "error");
        Validacion = true;
      } else {
        values.PDF64 = "No";
        values.XML64 = "No";
        values.UUID = "No";
        values.FechaPago = "No";
        Validacion = false;
      }
    }

    if (!Validacion) {
      const Peticion = new Request("/api/pagos/postPago", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": access,
        },
        method: "POST",
        body: JSON.stringify(values),
      });

      try {
        const response = await fetch(Peticion);
        const data = await response.json();
        if (data.status === "success") {
          setHabilarBoton(true);
          setGeneracion(true);
          setOpenMessage(true);
          setError(false);
        } else {
          setError(true);
          setOpenMessage(true);
          addAlert(data.message, "error");
          setGeneracion(false);
        }
      } catch (error) {
        console.error("Failed to fetch from postPago", error);
      }
    } else {
      setError(true);
      setOpenMessage(true);
      setGeneracion(false);
    }
  }

  const Archivos = async (evt) => {
    const { target } = evt;
    const { name, files, id } = target;

    if (files[0] === undefined) {
      return;
    }

    var base64;
    try {
      const base64pdf = await convertToBase64(files[0]);
      var ArrayAuxiliar = [];
      ArrayAuxiliar = base64pdf.split(",");
      base64 = ArrayAuxiliar[1];
    } catch (error) {
      console.error("Error while converting to Base64:", error);
    }

    const newValues = {
      ...values,
      [name]: files[0].name,
      [id]: base64,
    };
    setValues(newValues);
    // Restablecer el input para permitir seleccionar el mismo archivo nuevamente
    evt.target.value = null;
  };

  const ArchivoXML = async (evt) => {
    const { target } = evt;
    const { name, files, id } = target;

    if (files[0] === undefined) {
      return;
    }

    var base64;
    try {
      const base64pdf = await convertToBase64(files[0]);
      var ArrayAuxiliar = [];
      ArrayAuxiliar = base64pdf.split(",");
      base64 = ArrayAuxiliar[1];
    } catch (error) {
      console.error("Error while converting to Base64:", error);
    }

    const file = evt.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const xmlContent = event.target.result;
        parseXML(xmlContent, name, file.name, base64, id);
      };
      reader.readAsText(file);
    }

    // Restablecer el input para permitir seleccionar el mismo archivo nuevamente
    evt.target.value = null;
  };

  const parseXML = (xmlContent, name, FileName, Base64, id) => {
    // Parse the XML string into a JavaScript object
    parseString(xmlContent, { explicitArray: false }, (err, result) => {
      if (err) {
        console.error("Error parsing XML:", err);
        return;
      }

      // El CFDI 4.0 tiene la estructura que puedes explorar
      // Accede a la ruta correcta para obtener el total (dependiendo de la estructura del XML)
      console.log(result["cfdi:Comprobante"].$);
      //["cfdi:Comprobante"]["cfdi:Emisor"].$.Nombre
      console.log(result);

      //["cfdi:Comprobante"]["cfdi:Complemento"]["pago20:Pagos"]
      try {
        if (
          result["cfdi:Comprobante"] === undefined ||
          result["cfdi:Comprobante"].$ === undefined ||
          result["cfdi:Comprobante"]["cfdi:Complemento"]["pago20:Pagos"] ===
            undefined ||
          result["cfdi:Comprobante"]["cfdi:Complemento"]["pago20:Pagos"].$ ===
            undefined ||
          result["cfdi:Comprobante"].$.Fecha === undefined ||
          result["cfdi:Comprobante"].$.Folio === undefined ||
          result["cfdi:Comprobante"]["cfdi:Complemento"]["pago20:Pagos"][
            "pago20:Totales"
          ].$.MontoTotalPagos === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.Rfc === undefined ||
          result["cfdi:Comprobante"]["cfdi:Emisor"].$.Nombre === undefined ||
          result["cfdi:Comprobante"]["cfdi:Receptor"].$.Nombre === undefined ||
          result["cfdi:Comprobante"]["cfdi:Complemento"][
            "tfd:TimbreFiscalDigital"
          ].$.UUID === undefined
        ) {
          addAlert(
            "No cuenta con la estructura de un XML valida de un timbre del SAT",
            "error"
          );
          setError(true);
          setOpenMessage(true);
          return;
        }
      } catch (error) {
        addAlert(
          "No cuenta con la estructura de un XML valida de un timbre del SAT",
          "error"
        );
        setError(true);
        setOpenMessage(true);
        return;
      }

      // alert("Correcto");

      const newValues = {
        ...values,
        [name]: FileName,
        [id]: Base64,
        FechaPago: result["cfdi:Comprobante"].$.Fecha,
        Folio: result["cfdi:Comprobante"].$.Folio,
        Total:
          result["cfdi:Comprobante"]["cfdi:Complemento"]["pago20:Pagos"][
            "pago20:Totales"
          ].$.MontoTotalPagos,

        RFCEmisor: result["cfdi:Comprobante"]["cfdi:Emisor"].$.Rfc,
        RFCReceptor: result["cfdi:Comprobante"]["cfdi:Receptor"].$.Rfc,
        NombreEmisor: result["cfdi:Comprobante"]["cfdi:Emisor"].$.Nombre,
        NombreReceptor: result["cfdi:Comprobante"]["cfdi:Receptor"].$.Nombre,
        UUID: result["cfdi:Comprobante"]["cfdi:Complemento"][
          "tfd:TimbreFiscalDigital"
        ].$.UUID,
      };

      setValues(newValues);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setGeneracion(false);
    setOpenMessage(false);

    if (Generacion === true) {
      navigate("/Pagos");
    }
  };

  if (props.pagos.length === 0) {
    navigate("/Pagos");
  } else {
    if (IsSession) {
      if (operador === "Empleado") {
        return (
          <ThemeProvider theme={theme}>
            <Box sx={{ display: "flex" }}>
              <Header
                IsSession={IsSession}
                open={props.open}
                setOpen={props.setOpen}
                operador={Cookies.get("operador")}
                usuario={Cookies.get("usuario")}
              />
              <MenuLateral
                IsSession={IsSession}
                open={props.open}
                setOpen={props.setOpen}
              />
              <Box
                component="main"
                sx={{
                  flexGrow: 10,
                  height: "100vh",
                  overflow: "auto",
                }}
              >
                <Toolbar />
                <Typography
                  sx={{ my: 3, mx: 2 }}
                  align="center"
                  variant="h5"
                  component="h5"
                >
                  Registo del comprobante del pago #
                  {props.pagos[params.id - 1].PagoSAP}
                </Typography>

                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <StylePaperPrincipal variant="outlined">
                      {/* Acta constitutiva  */}
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          *Complemento de pago:
                        </Typography>
                        <TextField
                          fullWidth
                          align="left"
                          autoComplete="section-Pagos-Complemento"
                          size="small"
                          variant="outlined"
                          required
                          id="lblPDF"
                          name="lblPDF"
                          value={values.Complemento}
                          disabled={true}
                        />
                        {props.pagos[params.id - 1].EstatusComplemento !==
                        "Aceptada" ? (
                          <StyledButtonEditar
                            size="medium"
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            endIcon={<CloudUploadIcon />}
                            id="btnComplemento"
                            name="btnComplemento"
                            onChange={Archivos}
                            multiple={false}
                          >
                            cargar
                            <StyledInputFile
                              id="Complemento64"
                              name="Complemento"
                              type="file"
                              accept="application/pdf"
                            />
                          </StyledButtonEditar>
                        ) : (
                          values.Complemento64 !== "" && (
                            <StyledButtonEditar
                              size="medium"
                              component="label"
                              role={undefined}
                              tabIndex={-1}
                              endIcon={<PictureAsPdfIcon />}
                              id="PDF"
                              name="PDF"
                              onClick={(e) =>
                                createFile(
                                  values.Complemento64,
                                  "application/pdf"
                                )
                              }
                            >
                              Ver
                            </StyledButtonEditar>
                          )
                        )}
                      </StyledDiv>
                    </StylePaperPrincipal>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <StylePaperButton variant="elevation" elevation={0}>
                      <StyledButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={StyledTheme.submit}
                        disabled={habilarBoton}
                      >
                        Agregar
                      </StyledButton>
                    </StylePaperButton>
                  </Grid>
                </form>
              </Box>
            </Box>
            {Generacion === true && (
              <Snackbar
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                open={openMessage}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  Se agrego con exito los documentos!
                </Alert>
              </Snackbar>
            )}

            {error === true && errorMensaje.length > 0 && (
              <Snackbar
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                open={openMessage}
                autoHideDuration={30000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error" variant="filled">
                  {errorMensaje.length > 0 &&
                    errorMensaje.map((entrada) => {
                      return <div>{entrada.message}</div>;
                    })}
                  {/* {errorMensaje.length > 0 ? errorMensaje[0].message : ""} */}
                </Alert>
              </Snackbar>
            )}
          </ThemeProvider>
        );
      } else {
        return (
          <ThemeProvider theme={theme}>
            <Box sx={{ display: "flex" }}>
              <Header
                IsSession={IsSession}
                open={props.open}
                setOpen={props.setOpen}
                operador={Cookies.get("operador")}
                usuario={Cookies.get("usuario")}
              />
              <MenuLateral
                IsSession={IsSession}
                open={props.open}
                setOpen={props.setOpen}
              />
              <Box
                component="main"
                sx={{
                  flexGrow: 10,
                  height: "100vh",
                  overflow: "auto",
                }}
              >
                <Toolbar />
                <Typography
                  sx={{ my: 3, mx: 2 }}
                  align="center"
                  variant="h5"
                  component="h5"
                >
                  Registar documentos del pago #
                  {props.pagos[params.id - 1].PagoSAP}
                </Typography>

                <form onSubmit={handleSubmit}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <StylePaperPrincipal variant="outlined">
                      {/* Acta constitutiva  */}
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          *PDF:
                        </Typography>
                        <TextField
                          fullWidth
                          align="left"
                          autoComplete="section-Pagos-PDF"
                          size="small"
                          variant="outlined"
                          required
                          id="lblPDF"
                          name="lblPDF"
                          value={values.PDF}
                          disabled={true}
                        />
                        {props.pagos[params.id - 1].EstatusComplemento !==
                        "Aceptada" ? (
                          <StyledButtonEditar
                            size="medium"
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            endIcon={<CloudUploadIcon />}
                            id="btnPDF"
                            name="btnPDF"
                            onChange={Archivos}
                            multiple={false}
                          >
                            cargar
                            <StyledInputFile
                              id="PDF64"
                              name="PDF"
                              type="file"
                              accept="application/pdf"
                            />
                          </StyledButtonEditar>
                        ) : (
                          values.PDF64 !== "" && (
                            <StyledButtonEditar
                              size="medium"
                              component="label"
                              role={undefined}
                              tabIndex={-1}
                              endIcon={<PictureAsPdfIcon />}
                              id="PDF"
                              name="PDF"
                              onClick={(e) =>
                                createFile(values.PDF64, "application/pdf")
                              }
                            >
                              Ver
                            </StyledButtonEditar>
                          )
                        )}
                      </StyledDiv>
                      {/* Acta constitutiva  */}
                      <StyledDiv>
                        <Typography
                          align="left"
                          variant="subtitle2"
                          gutterBottom
                          sx={window.innerWidth > 500 && { width: 1 / 2 }}
                        >
                          *XML:
                        </Typography>
                        <TextField
                          fullWidth
                          align="left"
                          autoComplete="section-Pagos-XML"
                          size="small"
                          variant="outlined"
                          required
                          id="lblXML"
                          name="lblXML"
                          value={values.XML}
                          disabled={true}
                        />

                        {props.pagos[params.id - 1].EstatusComplemento !==
                        "Aceptada" ? (
                          <StyledButtonEditar
                            size="medium"
                            component="label"
                            role={undefined}
                            tabIndex={-1}
                            endIcon={<CloudUploadIcon />}
                            id="btnXML"
                            name="btnXML"
                            onChange={ArchivoXML}
                            multiple={false}
                          >
                            cargar
                            <StyledInputFile
                              id="XML64"
                              name="XML"
                              type="file"
                              accept="text/xml"
                            />
                          </StyledButtonEditar>
                        ) : (
                          values.PDF64 !== "" && (
                            <StyledButtonEditar
                              size="medium"
                              component="label"
                              role={undefined}
                              tabIndex={-1}
                              endIcon={<PictureAsPdfIcon />}
                              id="XML"
                              name="XML"
                              onClick={(e) =>
                                createFile(values.PDF64, "text/xml")
                              }
                            >
                              Ver
                            </StyledButtonEditar>
                          )
                        )}
                      </StyledDiv>
                    </StylePaperPrincipal>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <StylePaperButton variant="elevation" elevation={0}>
                      <StyledButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={StyledTheme.submit}
                        disabled={habilarBoton}
                      >
                        Agregar
                      </StyledButton>
                    </StylePaperButton>
                  </Grid>
                </form>
              </Box>
            </Box>
            {Generacion === true && (
              <Snackbar
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                open={openMessage}
                autoHideDuration={3000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  Se agrego con exito los documentos!
                </Alert>
              </Snackbar>
            )}

            {error === true && errorMensaje.length > 0 && (
              <Snackbar
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                open={openMessage}
                autoHideDuration={30000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="error" variant="filled">
                  {errorMensaje.length > 0 &&
                    errorMensaje.map((entrada) => {
                      return <div>{entrada.message}</div>;
                    })}
                  {/* {errorMensaje.length > 0 ? errorMensaje[0].message : ""} */}
                </Alert>
              </Snackbar>
            )}
          </ThemeProvider>
        );
      }
    } else {
      return <Navigate to="/" />;
    }
  }
}

Pago.propTypes = {
  pagos: PropTypes.array,
};

export default Pago;
